import * as zoid from 'zoid/dist/zoid.frameworks';

let IdgoAuthComponent = zoid.create({
  // The html tag used to render my component

  tag: "idgo-auth-component",

  // The url that will be loaded in the iframe or popup, when someone includes this component on their page

  url: "%PUBLIC_URL%/auth-button",

  // The size of the component on their page. Only px and % strings are supported

  dimensions: {
    width: "100%",
    height: "40px",
  },

  attributes: {
    iframe: {
      allow: "publickey-credentials-get *",
      scrolling: "no"
    }
  },

  // The properties they can (or must) pass down to my component. This is optional.

  props: {
    disabled: {
      type: "boolean",
      required: false,
    },
    loginValue: {
      type: "string",
      required: false,
    },
    buttonText: {
      type: "string",
      required: false,
    },
    buttonStyles: { // stringify'ed object react named styles
      type: "string",
      required: false,
    },
    onClick: {
      type: "function",
      required: true,
    },
    onAuthenticationResult: {
      type: "function",
      required: true,
    },
  },
});

export default IdgoAuthComponent;