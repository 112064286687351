import React from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { IonRouterOutlet } from '@ionic/react';

//Context
import { LoginProvider } from '../context/LoginContext';
import { FIDOProvider } from '../context/FIDOContext';
import { RPProvider } from '../context/RPContext';
import { VerifyCounterProvider } from '../context/VerifyCounterContext';

//Components
import PrivateRoute from '../components/routes/PrivateRoute';
import PublicRoute from '../components/routes/PublicRoute';
import Menu from '../components/layout/Menu/Menu';
import SessionTimeout from '../components/layout/SessionTimeOut/SessionTimeOut';

//Auth
import SignIn from '../components/auth/SignIn/SignIn';
import Dashboard from '../components/user/Dashboard/Dashboard';
import Fido from '../components/auth/Fido/Fido';
import VerificationCode from '../components/auth/VerificationCode/VerificationCode';
import MobileNumberVerify from  '../components/auth/MobileNumberVerify/MobileNumberVerify';
import AuthButton from '../components/auth/AuthButton/AuthButton'

//User Account
import ResetAccount from '../components/user/ResetAccount/ResetAccount';
import RelyingPartyList from '../components/user/RelyingPartyList/RelyingPartyList';
import Feedback from "../components/user/Feedback/Feedback";
import Notifications from "../components/user/Notifications/Notifications";

function AppRouter() {
  return (
    <IonReactRouter basename="/">
      <React.Fragment>
        <VerifyCounterProvider>
          <FIDOProvider>
            <LoginProvider>
              <RPProvider>
                <SessionTimeout />
                <Menu />
                <IonRouterOutlet id='main'>
                  <PublicRoute exact path='/a/:authKey/:customCode' name='SignIn'                    component={SignIn}             restricted={false} />
                  <PublicRoute exact path='/e/:enrollKey'           name='SignIn'                    component={SignIn}             restricted={false} />
                  <PublicRoute exact path='/r/:skrKey'              name='Fido'                      component={Fido}               restricted={false} />
                  <PublicRoute exact path='/v/:mnvKey/:customCode'  name='MobileNumberVerify'        component={MobileNumberVerify} restricted={false} />
                  <PublicRoute exact path='/verification-code'      name='VerificationCode'          component={VerificationCode}   restricted={false} />
                  <PublicRoute exact path='/auth-button'            name='AuthButton'                component={AuthButton}         restricted={false} />
                  <PrivateRoute exact path='/relying-party-list'    name='RelyingPartyList'          component={RelyingPartyList} />
                  <PrivateRoute exact path='/reset-account'         name='ResetAccount'              component={ResetAccount} />
                  <PrivateRoute exact path='/add-fido'              name='Fido'                      component={Fido} />
                  <PrivateRoute exact path='/dashboard'             name='Dashboard'                 component={Dashboard} />
                  <PrivateRoute exact path='/feedback'              name='Feedback'                  component={Feedback} />
                  <PrivateRoute exact path='/notifications'         name='Notifications'             component={Notifications} />
                  <PublicRoute exact path='/'                       name='SignIn'                    component={SignIn} />
                  <PublicRoute exact path='/ahs'                    name='SignIn'                    component={SignIn} />
                </IonRouterOutlet>
              </RPProvider>
            </LoginProvider>
          </FIDOProvider>
        </VerifyCounterProvider>
      </React.Fragment>
    </IonReactRouter>
  );
}

export default AppRouter;