import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 
{ IonPage
, IonContent
, IonButton
, IonText
, IonImg
} from '@ionic/react';
import { useToast } from '@agney/ir-toast';

//Utils
import { setAccessToken } from '../../../utils/session';
import { mobileNumberVerifyInfo, mobileNumberVerifyUserResponse } from '../../../utils/services';

//Images
import cozera from '../../../assets/logos/idgo_lockup.png';

//Styles
import styles from './MobileNumberVerify.module.css';

function MobileNumberVerify() {
  const Toast = useToast();
  const { mnvKey, customCode } = useParams();
  const [ subdomain, setSubdomain ] = useState();
  const [ smsMagicLinkRequestId, setSmsMagicLinkRequestId ] = useState();
  const [ loginDeviceIpInfo, setLoginDeviceIpInfo ] = useState({});
  const [ threatsDetected, setThreatsDetected ] = useState(false);
  const [ buttonsDisabled, setButtonsDisabled ] = useState(true);
  const [ choiseMade, setChoiseMade ] = useState(false);
  const [ initializing, setInitializing ] = useState(true);
  const [ rpInfo, setRpInfo ] = useState();

  useEffect(() => {
    async function __mobileNumberVerifyInfo(mnvKey, customCode) {
      const response = await mobileNumberVerifyInfo(mnvKey, customCode);
      setInitializing(false);
      if (response?.status===404) {
        setChoiseMade(true);
        
        Toast.create({ message: response?.message, color: 'warning', position: 'top', duration: 10000}).present();
        return;
      }
      if (response?.status!==200) {
        setChoiseMade(true);
        Toast.create({ message: response?.message, color: 'danger', position: 'top', duration: 10000}).present();
        return;
      }
      setButtonsDisabled(false);
      setSmsMagicLinkRequestId(response.data?.smsMagicLinkRequestId);
      setSubdomain(response.data?.subdomain);
      setRpInfo({name:response.data?.name, callCenterPhoneNumber:response.data?.callCenterPhoneNumber});
      setAccessToken(response.data.token, false);
      if (response.data?.loginDeviceIpInfo) {
        setLoginDeviceIpInfo(response.data?.loginDeviceIpInfo)
      }
      setThreatsDetected(response.data?.threatsDetected);
    }

    if (mnvKey) {
      if (mnvKey==='displayonly') { // for testing
        setInitializing(false);
        setRpInfo({name:'Enterprise 1', callCenterPhoneNumber:'111-222-3333'});
        setLoginDeviceIpInfo( {location : {city:'Portland', region: {name:'Oregon'}}} );
        if (customCode==='1') {
          setThreatsDetected(false);
        } else {
          setThreatsDetected(true);
        }
        return;
      }
      __mobileNumberVerifyInfo(mnvKey, customCode);
      return;
    }

  }, [mnvKey, customCode, Toast]);

  const handleUserResponse = async (userResponse) => {
    await mobileNumberVerifyUserResponse(subdomain, smsMagicLinkRequestId, userResponse);
    setChoiseMade(true);
  };

  const renderThreatsDetected = () => {
    return <>
      <IonText className={styles.ContainerText}>
        <div className={styles.BlockedHeader}>
          Verification Request Blocked
        </div>
        <div className={styles.BlockedParagraph1}>
          If you are attempting to Login to your Online Banking account, we are sorry for the inconvenience.  
          You may contact <span className={styles.emphasize}>{rpInfo?.name||'missing name'}</span> at <span className={styles.emphasizeNoWrap}>{rpInfo?.callCenterPhoneNumber||'missing phone number'}</span> for assistance.
        </div>
        <div className={styles.BlockedParagraph2}>
          If you are <b>not</b> attempting to Login to your Online Banking account, we highly recommend you reset your Online Banking account Password immediately.
        </div>
      </IonText>
    </>
  };

  const renderChoise = () => {
    return <>
      <IonText className={styles.ContainerText}>
        <div className={styles.ChoiceHeader}>
          Please Review
        </div>
        <div className={styles.ChoiceParagraph}>
          If someone is ASKING you to choose Yes, do NOT do so, this is a scam.
          No employee of <b>{rpInfo?.name||'missing name'}</b> will EVER ask you to do so.
        </div>
        { !loginDeviceIpInfo?.location?.city || !loginDeviceIpInfo?.location?.region?.name
          ? (
              <div className={styles.ChoiceParagraph}>
                Are you trying to sign in?
              </div>
            )
          : (
              <h2 className={styles.ChoiceParagraph}>
                <div>Are you trying to sign in from</div>
                <div><b>{loginDeviceIpInfo.location.city} {loginDeviceIpInfo.location.region.name}</b>?</div>
              </h2>
            )
        }
      </IonText>
      <div className={styles.ButtonBox}>
        <IonButton
          className={styles.ButtonNo}
          onClick={() => handleUserResponse('rejected')}
          disabled={buttonsDisabled}
          shape='round'
          color='danger'
          size='large'
          >No
        </IonButton>
        <div className={styles.ButtonSpacer}></div>
        <IonButton
          className={styles.ButtonYes}
          onClick={() => handleUserResponse('pass')}
          disabled={buttonsDisabled}
          shape='round'
          color='success'
          size='large'
          >Yes
        </IonButton>
      </div>
    </>
  };

  const renderChoiseMade = () => {
    return <>
      <IonText className={styles.ContainerText}>
        <h1>Thank you for confirming your Mobile Number.</h1>
        <h5>(This window or tab can now be closed)</h5>
      </IonText>
    </>
  };

  const render = () => {
    if (initializing   ) return <></>;
    if (choiseMade     ) return renderChoiseMade();
    if (threatsDetected) return renderThreatsDetected();
    return renderChoise();
  };

  return (
    <IonPage>
      <IonContent className='ion-padding'>
        <div className={styles.BasePage}>
          { render() }
          <div className={styles.Footer}>
            <IonImg src={cozera} data-testid='id-go-logo' alt='id-go-logo' className={styles.IonImg} />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default MobileNumberVerify;